import './styles/main.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useMemo, useState} from "react";
import InstallPage from "./pages/InstallPage";
import MainPage from "./pages/MainPage";
import Loading from "./elements/Loading";

function App() {
    const [appInitialized, setAppInitialized] = useState(false)

    const BX24 = useMemo(() => {
        const bx = window.BX24
        bx.init(() => setAppInitialized(true));
        return bx;
        // setAppInitialized(true)
        // return undefined;
    }, [])

    if (!appInitialized) {
        return <Loading isLoading={true}/>
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<MainPage BX24={BX24}/>} />
                <Route exact path="/install" element={<InstallPage BX24={BX24}/>} />
            </Routes>
        </BrowserRouter>
    )

}

export default App;
