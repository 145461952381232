import '../styles/pages/setup.scss'
import {useCallback, useEffect, useMemo, useState} from "react";
import Loading from "../elements/Loading";
import InlineNotification from "../elements/InlineNotification";
import getAPIClient from "../hooks/apiClient";


export default function InstallPage({BX24}) {
    const [isLoading, setIsLoading] = useState(true)

    const api = useMemo(() => getAPIClient(BX24), [])

    // Функция установки приложения
    const installApplication = useCallback(async () => {
        setNotificationText('')
        setIsLoading(true)
        try {
            await api.post(`/setup/install`)
            BX24.installFinish()
        } catch (e) {
            setIsLoading(false)
            setNotificationType('error')
            setNotificationText('Произошла непредвиденная ошибка')
        }
    }, [])

    const [notificationType, setNotificationType] = useState('error')
    const [notificationText, setNotificationText] = useState('')

    useEffect(() => {
        installApplication()
    }, [])

    return <>
        <Loading isLoading={isLoading}/>
        <div className={'center-main'}>
            <div className='setup-block setup-block-padding'>
                <InlineNotification
                    type={notificationType}
                    text={notificationText}
                    hideFlag={notificationText.length === 0}
                />
            </div>
        </div>
    </>
}
