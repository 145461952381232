import s from '../styles/pages/main.module.scss'
import BitrixUserSelector from "../elements/inputs/BitrixUserSelector";
import SubmitButton from "../elements/inputs/SubmitButton";
import InlineNotification from "../elements/InlineNotification";
import Header from "../elements/Header";
import {useCallback, useEffect, useMemo, useState} from "react";
import Loading from "../elements/Loading";
import getAPIClient from "../hooks/apiClient";
import { ReactComponent as SettingsLogo } from '../assets/settings-logo.svg';
import { ReactComponent as DocumentLogo } from "../assets/document.svg";
import BitrixUsersSelector from "../elements/inputs/BitrixUsersSelector";
import DefaultInput from "../elements/inputs/DefaultInput";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


import getAPIClientMocked from "../hooks/apiClientMocked";
import CustomDatePicker from "../elements/inputs/CustomDatePicker";


export default function MainPage({BX24}) {
    const [isLoading, setIsLoading] = useState(true)

    // Флаги, которые говорят что юзер что-то поменял после последнего получения данных
    const [somethingUpdatedFlag, setSomethingUpdatedFlag] = useState(false)

    const api = useMemo(() => getAPIClient(BX24), [])


    const [globalNotificationMessage, setGlobalNotificationMessage] = useState('')
    const [globalNotificationType, setGlobalNotificationType] = useState('error')

    const [inlineNotificationMessageMain, setInlineNotificationMessageMain] = useState('')
    const [inlineNotificationTypeMain, setInlineNotificationTypeMain] = useState('error')

    const [inlineNotificationMessageManualReport, setInlineNotificationMessageManualReport] = useState('')
    const [inlineNotificationTypeManualReport, setInlineNotificationTypeManualReport] = useState('error')

    const [currentSettings, setCurrentSettings] = useState()
    // Получение конфига, установка текущего СП
    useEffect(() => {
        api.get(`/bitrix_portals/settings`).then(response => {
            setCurrentSettings(response.data)
            setIsLoading(false)
        })
    }, [])

    // Обновление конфигурации приложения (смарт-процесс, маппинги)
    const saveChanges = useCallback(async () => {
        let errorText = ''
        setGlobalNotificationMessage('')

        // отправка запрос на обновление settings
        if (somethingUpdatedFlag) {
            try {
                await api.post(`/bitrix_portals/settings`, currentSettings)
                setSomethingUpdatedFlag(false)
                setInlineNotificationTypeMain('success')
                setInlineNotificationMessageMain('Настройки обновлены')
            } catch (e) {
                setInlineNotificationTypeMain('error')
                setInlineNotificationMessageMain('Ошибка при обновлении настроек')
            }
        }
    }, [somethingUpdatedFlag, currentSettings])

    const [manualReportFromDate, setManualReportFromDate] = useState(new Date())
    const makeManualReport = useCallback(async () => {
        setGlobalNotificationMessage('')
        if (!!manualReportFromDate) {
            try {
                await api.post('/reports/manual_report', {date_from: manualReportFromDate})
                setInlineNotificationTypeManualReport('success')
                setInlineNotificationMessageManualReport('Запрос на формирование отчета отправлен. Через некоторое время ответственному придет уведомление')
            } catch (e) {
                setInlineNotificationTypeManualReport('error')
                setInlineNotificationMessageManualReport('Произошла ошибка при отправке запроса на формирование отчета')
            }
        }
    }, [manualReportFromDate])

    const [selectedMenuItem, setSelectedMenuItem] = useState('settings') // settings, manual_report
    if (isLoading) {
        return (
            <Loading isLoading={isLoading}/>
        )
    }
    return (
        <>
            <Header/>
            <div className={s.main}>
                <InlineNotification
                    type={globalNotificationType}
                    text={globalNotificationMessage}
                    hideFlag={globalNotificationMessage.length === 0}
                />
                <div className={s.main_container}>
                    <div className={s.left_side_menu}>
                        <div className={s.menu_list}>
                            <div
                                className={s.menu_item + ' ' + (selectedMenuItem === 'settings' ? s.active_menu_item : '')}
                                onClick={() => setSelectedMenuItem('settings')}
                            >
                                <div className={s.menu_item_icon}><SettingsLogo/></div>
                                <div className={s.menu_item_text}>Общие настройки</div>
                            </div>
                            <div
                                className={s.menu_item + ' ' + (selectedMenuItem === 'manual_report' ? s.active_menu_item : '')}
                                onClick={() => setSelectedMenuItem('manual_report')}
                            >
                                <div className={s.menu_item_icon}><DocumentLogo/></div>
                                <div className={s.menu_item_text}>Создание отчета</div>
                            </div>
                        </div>
                    </div>
                    <div className={s.active_block}>
                        {(selectedMenuItem === 'settings') && <div className={s.settings}>
                            <div className={s.settings_item}>
                                <BitrixUserSelector
                                    title={'Ответственный в СП Звонки (уведомления по звонкам)'}
                                    selectedUser={{name: currentSettings.b24_calls_responsible_name, id: currentSettings.b24_calls_responsible_id}}
                                    setSelectedUser={(user) => {
                                        let tmp = JSON.parse(JSON.stringify(currentSettings));
                                        tmp['b24_calls_responsible_id'] = user.id
                                        tmp['b24_calls_responsible_name'] = user.name
                                        setInlineNotificationMessageMain('')
                                        setSomethingUpdatedFlag(true)
                                        setCurrentSettings(tmp)
                                    }}
                                    BX24={BX24}
                                />
                            </div>
                            <div className={s.settings_item}>
                                <BitrixUserSelector
                                    title={'Ответственный в СП Отчеты (уведомления по отчетам)'}
                                    selectedUser={{name: currentSettings.b24_reports_responsible_name, id: currentSettings.b24_reports_responsible_id}}
                                    setSelectedUser={(user) => {
                                        let tmp = JSON.parse(JSON.stringify(currentSettings));
                                        tmp['b24_reports_responsible_id'] = user.id
                                        tmp['b24_reports_responsible_name'] = user.name
                                        setSomethingUpdatedFlag(true)
                                        setInlineNotificationMessageMain('')
                                        setCurrentSettings(tmp)
                                    }}
                                    BX24={BX24}
                                />
                            </div><div className={s.settings_item}>
                                <BitrixUsersSelector
                                    title={'Наблюдатели в СП Отчеты'}
                                    selectedUsers={currentSettings.b24_observers_responsible_users}
                                    setSelectedUsers={(users) => {
                                        console.log('users', users)
                                        let tmp = JSON.parse(JSON.stringify(currentSettings));
                                        tmp['b24_observers_responsible_users'] = users.map(u => {return {
                                            id: u.id,
                                            name: u.name
                                        }})
                                        setSomethingUpdatedFlag(true)
                                        setInlineNotificationMessageMain('')
                                        setCurrentSettings(tmp)
                                    }}
                                    BX24={BX24}
                                />
                            </div><div className={s.settings_item}>
                                <DefaultInput
                                    title={'API-токен транскрибатора'}
                                    value={currentSettings.transcribator_api_token}
                                    onChangeFunction={(e) => {
                                        let tmp = JSON.parse(JSON.stringify(currentSettings));
                                        tmp['transcribator_api_token'] = e.target.value
                                        setSomethingUpdatedFlag(true)
                                        setInlineNotificationMessageMain('')
                                        setCurrentSettings(tmp)
                                    }}
                                />
                            </div><div className={s.settings_item}>
                                <DefaultInput
                                    title={'Периодичность создания отчета (в секундах)'}
                                    value={currentSettings.update_interval}
                                    onChangeFunction={(e) => {
                                        let tmp = JSON.parse(JSON.stringify(currentSettings));
                                        tmp['update_interval'] = e.target.value.replace(/[^0-9]/g, "");
                                        setSomethingUpdatedFlag(true)
                                        setInlineNotificationMessageMain('')
                                        setCurrentSettings(tmp)
                                    }}
                                />
                            </div><div className={s.settings_item}>
                                <DefaultInput
                                    title={'Максимум звонков в отчете'}
                                    value={currentSettings.limit_of_calls_in_report}
                                    onChangeFunction={(e) => {
                                        let tmp = JSON.parse(JSON.stringify(currentSettings));
                                        tmp['limit_of_calls_in_report'] = e.target.value.replace(/[^0-9]/g, "");
                                        setSomethingUpdatedFlag(true)
                                        setInlineNotificationMessageMain('')
                                        setCurrentSettings(tmp)
                                    }}
                                />
                            </div>
                            <SubmitButton
                                onClick={saveChanges}
                                disabled={!somethingUpdatedFlag}
                                className={s.submit}
                                text={'Сохранить'}
                            />
                            <div className={s.settings_item}>
                                <InlineNotification
                                    type={inlineNotificationTypeMain}
                                    text={inlineNotificationMessageMain}
                                    hideFlag={!inlineNotificationMessageMain}
                                />
                            </div>
                        </div>}
                        {(selectedMenuItem === 'manual_report' && <div className={s.settings}>
                            <div className={s.settings_item}>
                                <CustomDatePicker
                                    title={'Начало отчетного периода'}
                                    value={manualReportFromDate}
                                    onChangeFunction={(date) => {
                                        setManualReportFromDate(date)
                                        setInlineNotificationMessageManualReport('')
                                    }}
                                />
                            </div>
                            <div className={s.settings_item}>
                                <SubmitButton
                                    onClick={makeManualReport}
                                    className={s.submit}
                                    disabled={!manualReportFromDate}
                                    text={'Сделать отчет'}
                                />
                            </div>
                            <InlineNotification
                                type={inlineNotificationTypeManualReport}
                                text={inlineNotificationMessageManualReport}
                                hideFlag={!inlineNotificationMessageManualReport}
                            />
                        </div>)}
                    </div>
                </div>
            </div>
        </>
    )
}