import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/elements/inputs/custom-datepicker.scss'

export default function CustomDatePicker(
    {className = "", title, value, onChangeFunction}
) {
    return (
        <div className={"input-contain " + className}>
            <DatePicker
                selected={value}
                onChange={onChangeFunction}
                className={'dp-t'}
            />
            <label className="placeholder-text" htmlFor="fname" id="placeholder-fname">
                <div className="text">{title}</div>
            </label>

        </div>
    )
}
